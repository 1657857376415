define(['app', 'elementViewed', 'viewport', '$console','siteObj'], function (app, elementViewed, viewPort, $console, siteObj) {


  var productDescription = function () {

    var component = {};
    component.app = app;
    component.elementViewed = elementViewed;
    component.viewPort = viewPort;
    component.openTabElem = null;

    var _config = {
      dataAttributes: {
        listItemsSelector: '[data-item]',
        listItemControlSelector: '[data-item-control]',
        innerContentSelector: '[data-item-content]',
        keepOpenContentAttribute: 'data-item-content-keep-open',
        iconExpandSelector: '[data-icon=expand]',
        iconCollapseSelector: '[data-icon=collapse]',
        tabbedContentSelector: '[data-tabbed-content]',
        tabbedReevooSelector: '[data-tabbed-reevoo]',
        ingredientsSelector: '[data-information-component=ingredients]',
        nutritionalInformationSelector: '[data-information-component=nutritionalInformation]',
        suggestedUseSelector: '[data-information-component=suggestedUse]',
        tabTitleAttribute: 'data-tab-title',
        stringTemplatePath: 'data-string-template-path',
        alwaysAccordions: 'data-always-accordions',
        defaultOpen: 'data-default-open',
      },
      selectors: {
        transcriptButton: '.productDescription_contentProperties_list_YouTube_transcriptButton',
        transcriptCheck: '[data-transcript]',
        reviews: '.productDescription_contentPropertyListItem_reviews',
        openAccordionOnMobileSelector: '.openAccordionOnMobile',
        hideElement: '.productDescription_contentProperties'
      },
      subscribeChannel: {
        description: 'productDescription/newDescription',
        openItem: 'productDescription/openItem',
        update: 'fullProductDescription/new',
        productReviewStarsScroll: 'productReviewStars/scroll'
      },
      classes: {
        hideContent: 'productDescription_contentProperties-hide',
        iconHide: 'productDescription_icon-hide',
        activeTab: 'productDescription_contentPropertyListItem-active',
        tabTitleClass: '.productDescription_contentPropertyHeading-tabbed',
        reevooReviewsClass: 'productDescription_contentPropertyListItem-Reviews',
        video: '.athenaProductDescriptionList_contentProperty-videoReference.videoExperiment',
        productTitle: '.productName_title'
      },
      label: {
        descriptionComponent: 'productDescription component',
        descriptionComponentClickedPosition: 'productDescription component clicked tab ',
        clicked: 'Clicked',
        viewed: 'Viewed',
      },
    };

      var _init = function (element, userDriven) {
      if (siteObj.showReevooReviews) {
        require(['reevooClient']);
      }


      component.element = element;

      component.listItems = [...component.element.querySelectorAll(component.config.dataAttributes.listItemsSelector)];
      component.listItemControls = component.element.querySelectorAll(component.config.dataAttributes.listItemControlSelector);
      component.tabbedContainer = component.element.querySelector(component.config.dataAttributes.tabbedContentSelector);
      component.tabbedReevooContainer = component.element.querySelector(component.config.dataAttributes.tabbedReevooSelector);
      component.stringTemplatePath = component.element.getAttribute(component.config.dataAttributes.stringTemplatePath);
      component.reviews = component.element.querySelector(component.config.selectors.reviews);
      component.openFirstAccordionDrawerOnMobile = document.querySelector(component.config.selectors.openAccordionOnMobileSelector);
      component.hideElement = document.querySelectorAll(component.config.selectors.hideElement);
      component.youtubeVideoContiner = document.querySelectorAll(component.config.classes.video);
      component.productTitle = document.querySelector(component.config.classes.productTitle);



      // if this container is in-view on page load, just fire the event
      if (component.viewPort.isElementVisible(component.element)) {
        component.triggerTrackingEvent(component.config.label.viewed,
          component.config.label.descriptionComponent);
      } else {
        //otherwise register the view handler to fire it
        component.elementViewed(component.element, component.trackComponentViewed);
      }

      component.addClickEventListeners();

      if(!component.element.hasAttribute(component.config.dataAttributes.alwaysAccordions)) {
        var mediaQuery = window.matchMedia('screen and (min-width: 1180px)');
        if (mediaQuery.matches) {
          component.listItemToggle(component.listItems[0], true);
        } else {
          var listenerFunction = function (changed) {
            if (changed.matches) {
              component.listItemToggle(component.listItems[0], true);
              mediaQuery.removeListener(listenerFunction);
            }
          };
          if(typeof(component.openFirstAccordionDrawerOnMobile) != 'undefined' && component.openFirstAccordionDrawerOnMobile != null) {
            component.listItemToggle(component.listItems[0], true);
          }
          mediaQuery.addListener(listenerFunction);
        }
      }
      else {
        if (siteObj.features.alwaysClosedPDPAccordions) {
          return
        } else {
          component.listItemToggle(component.listItems[0], true);
        }
      }

        if (!userDriven) {
        component.subscribeAndConsume();
      }

      component.transcriptCheck = element.querySelector(component.config.selectors.transcriptCheck);
      component.transcriptButton = element.querySelector(component.config.selectors.transcriptButton);

      if(component.transcriptButton && component.transcriptCheck && component.transcriptCheck.innerHTML === ''){
        component.transcriptButton.remove();
      }

      if(component.youtubeVideoContiner) {
        component.videoContainerTrackingEvent();
      }

      return component;
    };

    var _addClickEventListeners = function () {
      for (var i = 0; i < component.listItemControls.length; ++i) {
        var item = component.listItems[i];
        var itemControl = component.listItemControls[i];
        itemControl.addEventListener('click', component.getClickedListEvent(item));
        component.attachTrackingClickListener(item, i + 1);
      }
    };

    var _attachTrackingClickListener = function (target, i) {
      target.addEventListener('click', function () {
        component.trackTabClicked(i);
      });
    };

    var _subscribeAndConsume = function () {
      app.subscribe(component.config.subscribeChannel.update, component.retrieveNewProductDescription);

      const { messages } = app.subscribe(component.config.subscribeChannel.openItem, component.openItemListener);
      if (messages && messages.length) {
        // only consume last
        const cb = messages[messages.length - 1];
        component.openItemListener(cb);
      }
    };

    var _trackTabClicked = function(listPosition) {
      const tabTitle = component.getTabTitle(listPosition);
      if (tabTitle === null) {
        $console.warn('ERROR: Cannot get the title of the clicked tab.');
        return;
      }
      component.triggerTrackingEvent(component.config.label.clicked,
        component.config.label.descriptionComponentClickedPosition
        + tabTitle);
    };

    var _trackComponentViewed = function () {
      component.triggerTrackingEvent(component.config.label.viewed,
        component.config.label.descriptionComponent);
    };

    var _triggerTrackingEvent = function (action, label) {
      app.publish('tracking/record', 'Product | Description', action, label);
    };

    const _videoContainerTrackingEvent = function () {
      if (component.youtubeVideoContiner.length !== 0) {
        component.productTitle.click();
        component.youtubeVideoContiner.forEach(() => {
          window.focus();
          window.addEventListener("blur", () => {
            setTimeout(() => {
              if (document.activeElement.tagName === "IFRAME") {
                app.publish('tracking/record', 'Product Video Iframe', 'Click');
              }
            });
          }, { once: true });
        })
      }
    };

    var _isDesktopListView = function () {
      if (component.listItems.length) {
        var iconExpand = component.listItems[0].querySelector(
          component.config.dataAttributes.iconExpandSelector);
        var iconCollapse = component.listItems[0].querySelector(
          component.config.dataAttributes.iconCollapseSelector);
        var iconExpandDisplay = window.getComputedStyle(iconExpand, null)
          .getPropertyValue('display');
        var iconCollapseDisplay = window.getComputedStyle(iconCollapse, null)
          .getPropertyValue('display');

        if (iconExpandDisplay === 'none' && iconCollapseDisplay === 'none'
          && !component.tabbedContainer) {
          return true;
        }
      }

      return false;
    };

    var _getClickedListEvent = function (item) {
      return () => component.listItemToggle(item);
    };

    var _listItemToggle = function (item, firstElem) {

      if ((!component.isDesktopListView() || component.element.hasAttribute(component.config.dataAttributes.alwaysAccordions)) && item) {
        
        if (item.hasAttribute(component.config.dataAttributes.defaultOpen) && firstElem) {
          return;
        }
        var itemContent = item.querySelector(component.config.dataAttributes.innerContentSelector);
        var itemControl = item.querySelector(component.config.dataAttributes.listItemControlSelector);
        var isHidden = app.element.hasClass(component.config.classes.hideContent, itemContent);

        if (isHidden || firstElem) {
          component.expandListItem(item, itemContent, itemControl);
        } else {
          component.collapseListItem(item, itemContent, itemControl);
        }
      }
    };

    var _expandListItem = function (item, itemContent, itemControl) {
      component.closeAllTabs();
      component.openThisTab(item, itemContent, itemControl);
      const isReevoo = item.classList.contains(component.config.classes.reevooReviewsClass);

      if(component.tabbedContainer){
        if (!isReevoo) {
          var content = itemContent.innerHTML;
          component.tabbedContainer.innerHTML = '';
          component.tabbedContainer.innerHTML = content;
          component.tabbedContainer.setAttribute('aria-labelledby', itemControl.id);
          if(component.tabbedReevooContainer) component.tabbedReevooContainer.style.display = 'none';
        } else {
          if(component.tabbedReevooContainer) component.tabbedReevooContainer.style.display = 'flex';
          component.tabbedContainer.innerHTML = '';
        }
      }

      component.hideElement.forEach((el) => {
        if (el.classList.contains(component.config.classes.hideContent)) {
          el.setAttribute('hidden', 'until-found');
        }
      })
    };

    var _collapseListItem = function (item, itemContent, itemControl) {

      const isReevoo = item.classList.contains(component.config.classes.reevooReviewsClass);

      var mediaQuery = window.matchMedia('screen and (min-width: 1180px)');

      var iconExpand = item.querySelector(component.config.dataAttributes.iconExpandSelector);
      var iconCollapse = item.querySelector(component.config.dataAttributes.iconCollapseSelector);

      app.element.setAttribute('aria-expanded', 'false', itemControl);

      if (isReevoo && !mediaQuery.matches) {
        component.tabbedReevooContainer.style.display = 'none';
      }

      app.element.addClass(component.config.classes.hideContent, itemContent);
      app.element.removeClass(component.config.classes.activeTab, item);
      app.element.addClass(component.config.classes.iconHide, iconCollapse);
      app.element.removeClass(component.config.classes.iconHide, iconExpand);

      component.hideElement.forEach((el) => {
        if (el.classList.contains(component.config.classes.hideContent)) {
          el.setAttribute('hidden', 'until-found');
        }
      })
    };

    var _closeAllTabs = function () {
      for (var i = 0; i < component.listItems.length; ++i) {
        var itemContent = component.listItems[i].querySelector(component.config.dataAttributes.innerContentSelector);
        var itemControl = component.listItems[i].querySelector(component.config.dataAttributes.listItemControlSelector);

        if (itemContent) {
          var isHidden = app.element.hasClass(component.config.classes.hideContent, itemContent);

          if (!isHidden) {
            component.collapseListItem(component.listItems[i], itemContent, itemControl);
          }
        }
      }
    };

    var _openThisTab = function (item, itemContent, itemControl) {
      component.openTabElem = item;

      var iconExpand = item.querySelector(component.config.dataAttributes.iconExpandSelector)
      var iconCollapse = item.querySelector(component.config.dataAttributes.iconCollapseSelector)

      app.element.setAttribute('aria-expanded', 'true', itemControl);
      app.element.removeClass(component.config.classes.hideContent, itemContent);
      app.element.addClass(component.config.classes.activeTab, item);
      app.element.removeClass(component.config.classes.iconHide, iconCollapse);
      app.element.addClass(component.config.classes.iconHide, iconExpand);
      component.setScrollPosition(item);

      component.hideElement.forEach((el) => {
        if (!el.classList.contains(component.config.classes.hideContent)) {
          el.removeAttribute('hidden');
        }
      })
    };

    var _setScrollPosition = function (item) {
      var defaultOffset = 100;

      if (document.querySelector('.westendHeader-sticky') != null){
        let header = document.querySelector('.westendHeader-sticky');
        defaultOffset = parseInt(window.getComputedStyle(header).getPropertyValue('height'));
      }

      if (item != component.listItems[0]){
        let dims = item.getBoundingClientRect();
        window.scrollTo(dims.left, dims.top + window.scrollY - (defaultOffset * 2));
      }
    };

    var _getTabTitle = function (tabIndex) {
      const listItem = component.listItems[tabIndex - 1];
      if (listItem === undefined) {
        return null;
      }
      const titleElement = listItem.querySelector(component.config.classes.tabTitleClass);
      if (titleElement === undefined) {
        return null;
      }
      if(titleElement) {
        return titleElement.getAttribute(component.config.dataAttributes.tabTitleAttribute);
      } else {
        return null;
      }
    };

    var _checkSubscribeMessages = function (subscription, callback) {
      if (subscription.messages.length > 0) {
        var lastItem = subscription.messages.slice(-1)[0];
        callback(lastItem);
      }
    };

    var _getNewSuggestedUse = function (productId) {
      app.ajax.get({
        url: `/${productId}.suggestedUse`,
        success: item => component.newDescriptionSuccessHandler(item,
          component.config.dataAttributes.suggestedUseSelector),
        error: component.suggestedUseErrorHandler,
      });
    };

    var _suggestedUseErrorHandler = function () {
      $console.error('ERROR: Could not retrieve child product suggested use information');
    };

    var _getNewIngredients = function (productId) {
      app.ajax.get({
        url: `/${productId}.ingredients`,
        success: item => component.newDescriptionSuccessHandler(item,
          component.config.dataAttributes.ingredientsSelector),
        error: component.ingredientsErrorHandler,
      });
    };

    var _ingredientsErrorHandler = function () {
      $console.error('ERROR: Could not retrieve child product ingredients information');
    };

    var _getNewNutritionalInfo = function (productId) {
      app.ajax.get({
        url: `/${productId}.nutritionalInformation`,
        success: item => component.newDescriptionSuccessHandler(item,
          component.config.dataAttributes.nutritionalInformationSelector),
        error: component.nutritionalInfoErrorHandler,
      });
    };

    var _nutritionalInfoErrorHandler = function () {
      $console.error('ERROR: Could not retrieve child product nutritional information');
    };

    var _newDescriptionSuccessHandler = function (item, selector) {
      var tempElem = document.createElement('div');
      tempElem.innerHTML = item;

      const newDescriptionContainer = tempElem.querySelector(selector);
      if (!newDescriptionContainer) {
        return;
      }
      const newDescription = newDescriptionContainer.firstElementChild.innerHTML;

      var descriptionContainer = component.element.querySelector(selector);

      if (!descriptionContainer) {
        return;
      }
      descriptionContainer.innerHTML = newDescription;

      component.closeAllTabs();
      component.listItemToggle(component.openTabElem);
    };

    const _retrieveNewProductDescription = (childId, enableChildSkuDescription) => {

      var getUrl = '/' + childId + '.fullDescription?';
      if (component.stringTemplatePath !== null) {
        getUrl +=
          '&stringTemplatePath=' + component.stringTemplatePath;
      }

      if (enableChildSkuDescription !== '') {
        getUrl +=
          '&enableChildProductDescription=' + enableChildSkuDescription;
      }

      return new Promise(() => app.ajax.get({
        url: getUrl,
        success: (data) => { component.successHandler(data, childId); },
        error: component.errorHandler,
      }));
    };

    const _successHandler = function (response, childId) {
      component.updateProductDescription(response, childId);
    };

    const _errorHandler = function () {
      $console.error(
        'ERROR: Could not retrieve new product description');
    };

    const _updateProductDescription = (response, childId) => {
      const parent = component.element.parentNode;
      if (parent !== null) {
        parent.innerHTML = response;
        const newElement = parent.querySelector('[data-component=productDescription]');
        component.init(newElement, true);

        if (siteObj.showReevooReviews) {
          component.retrieveReviewId(childId);
        }
      }
    };

    const _retrieveReviewId = (childId) => {
      const getUrl = '/' + childId + '.review?&stringTemplatePath=components/athenaProductReevooReviews/athenaProductReevooReviews';

      return new Promise(() => app.ajax.get({
        url: getUrl,
        success: component.updateReevooReviews,
        error: component.updateReevooReviewsErrorHandler,
      }));
    };

    const _updateReevooReviews = (response) => {
      const el = component.element.querySelector('[data-embedded-reviews]');

      if(el === null) {return}

      el.parentNode.innerHTML = response;
    };

    const _updateReevooReviewsErrorHandler = () => {
      $console.error(
        'ERROR: Could not retrieve Reevoo embedded reviews');
    };

    const _openItemListener = (condition) => {
      if (!(condition instanceof Function)) {
        $console.warn('Argument has to be a function.');
        return;
      }

      const index = component.listItems.findIndex(condition);

      if (index !== -1) {
        component.listItemToggle(component.listItems[index], true);
      }
    };

    component.init = _init;
    component.config = _config;
    component.addClickEventListeners = _addClickEventListeners;
    component.subscribeAndConsume = _subscribeAndConsume;
    component.isDesktopListView = _isDesktopListView;
    component.getClickedListEvent = _getClickedListEvent;
    component.listItemToggle = _listItemToggle;
    component.expandListItem = _expandListItem;
    component.collapseListItem = _collapseListItem;
    component.closeAllTabs = _closeAllTabs;
    component.openThisTab = _openThisTab;
    component.trackTabClicked = _trackTabClicked;
    component.triggerTrackingEvent = _triggerTrackingEvent;
    component.trackComponentViewed = _trackComponentViewed;
    component.attachTrackingClickListener = _attachTrackingClickListener;
    component.getTabTitle = _getTabTitle;
    component.checkSubscribeMessages = _checkSubscribeMessages;
    component.getNewSuggestedUse = _getNewSuggestedUse;
    component.suggestedUseErrorHandler = _suggestedUseErrorHandler;
    component.getNewIngredients = _getNewIngredients;
    component.ingredientsErrorHandler = _ingredientsErrorHandler;
    component.getNewNutritionalInfo = _getNewNutritionalInfo;
    component.nutritionalInfoErrorHandler = _nutritionalInfoErrorHandler;
    component.newDescriptionSuccessHandler = _newDescriptionSuccessHandler;
    component.updateProductDescription = _updateProductDescription;
    component.retrieveReviewId = _retrieveReviewId;
    component.retrieveNewProductDescription = _retrieveNewProductDescription;
    component.updateReevooReviews = _updateReevooReviews;
    component.updateReevooReviewsErrorHandler = _updateReevooReviewsErrorHandler;
    component.successHandler = _successHandler;
    component.errorHandler = _errorHandler;
    component.openItemListener = _openItemListener;
    component.setScrollPosition = _setScrollPosition;
    component.videoContainerTrackingEvent = _videoContainerTrackingEvent;
    return component;
  };

  return productDescription;
});
